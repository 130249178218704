import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { AccountService } from '@iris/iris-authentication';
import * as moment from 'moment';
import {
  MenuNewTag,
  ModuleFirstViewDate,
} from 'src/lib/models/moduleFirstViewDate.model';
import { NavItem } from 'src/lib/models/navigation.model';
import { ComplementsService } from 'src/lib/services/complements/complements.service';
import { Complement } from 'src/lib/services/complements/complements.types';
import { SubMenuPermissions } from 'src/lib/services/menu/menu.model';
import { MenuService } from 'src/lib/services/menu/menu.service';
import { MetricsService } from 'src/lib/services/metrics/metrics.service';
import { AccountsService } from 'src/lib/services/accounts/accounts.service';
import { UnsubscribeOnDestroyAdapter } from '@iris/iris-base';

declare var window: any;
const daysSinceModuleFirstViewToShowNewTag = 15;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  navItems: NavItem[];
  menusNewTag: MenuNewTag[] = [
    {
      name: 'IrisPay',
    },
    {
      name: 'IrisCard',
    },
  ];
  modulesPatch: ModuleFirstViewDate[] = [];

  isActiveSubmenu = [
    SubMenuPermissions.TRANSFERS,
    SubMenuPermissions.MYCOMPANY,
    SubMenuPermissions.MYPROFILE,
  ];

  isSubmenuSelected = true;

  constructor(
    private menuService: MenuService,
    private changeDetector: ChangeDetectorRef,
    private metricsService: MetricsService,
    private accountService: AccountService,
    private complementsService: ComplementsService,
    private accountsService: AccountsService,
  ) {
    super();
    window.refreshNavbar = () => {
      this.ngOnInit();
    };
  }

  @HostListener('window:selectedSubmenu')
  ngOnInit() {
    this.menuService.getMenus().then(m => {
      m.forEach(m => {
        m.active = false;
      });

      let route = window.location.pathname;
      if (route === '/') route = '/dashboard';

      const basePath = this.getResetFocusRoute(route) ?? route;
      const navItem = m.find(k => basePath.startsWith(k.href));
      if (navItem) navItem.active = true;

      this.addNewTagLabel();
      this.navItems = m;
      this.changeDetector.detectChanges();
    });
  }

  navigate(route: NavItem) {
    if (route.href === undefined || route.href === null) {
      return;
    }

    const customEvent = new CustomEvent('onChangeRoute', {
      detail: { route: route.href },
    });
    window.dispatchEvent(customEvent);

    this.navItems.forEach(m => {
      m.active = false;
    });

    route.active = true;
    this.metricsService.registrerMetrics(route?.title);
    this.updateFirstViewDate(route?.title);

    this.changeDetector.detectChanges();
  }

  private updateFirstViewDate(routeName: string) {
    if (this.isNavigatingToModule(routeName)) {
      const unseenModules = this.menusNewTag.filter(
        m => m?.firstViewDate == undefined,
      );
      const moduleToUpdate = unseenModules.find(
        module => module.name.toUpperCase() == routeName.toUpperCase(),
      );

      if (moduleToUpdate) {
        this.modulesPatch = [
          ...this.modulesPatch,
          { moduleName: moduleToUpdate.name, firstViewDate: moment() },
        ];
        this.subs.add(
          this.accountsService
            .updateFirstViewDates(this.modulesPatch)
            .subscribe(),
        );
      }
    }
  }

  private isNavigatingToModule(routeName: string) {
    return this.menusNewTag.find(
      menuNewTag => menuNewTag.name.toUpperCase() == routeName.toUpperCase(),
    );
  }

  private getResetFocusRoute(route: string): string | undefined {
    return this.menuService.complementsSetFocus.get(route);
  }

  private addNewTagLabel() {
    const getMenusFirstViewDates = this.getMenusFirstViewDates();
    const getMenusCampaignDates = this.getMenusCampaignDates();

    Promise.all([getMenusFirstViewDates, getMenusCampaignDates]).then(() => {
      const menusToAddNewTag = this.menusNewTag.filter(
        menuNewTag =>
          this.isCampaignOn(menuNewTag) &&
          this.isFirstViewDateLessThan15Days(menuNewTag),
      );

      menusToAddNewTag.map(menuToAddNewTag => {
        const navItem = this.navItems.find(
          navItem =>
            navItem.title.toUpperCase() == menuToAddNewTag.name.toUpperCase(),
        );
        if (navItem) navItem.highlight = true;
      });
      this.changeDetector.detectChanges();
    });
  }

  private getMenusCampaignDates() {
    return this.complementsService
      .get()
      .toPromise()
      .then((complements: Complement[]) => {
        complements.map(complement => {
          const menuNewTag = this.menusNewTag.find(
            menu => menu.name == complement.name,
          );

          if (menuNewTag) {
            menuNewTag.campaignStartDate = moment(
              complement.campaignStartDate,
              'YYYY-MM-DD',
            );
            menuNewTag.campaignEndDate = moment(
              complement.campaignEndDate,
              'YYYY-MM-DD',
            );
          }
        });
      });
  }

  private getMenusFirstViewDates() {
    return this.accountService
      .me()
      .toPromise()
      .then((r: any) => {
        if (r.moduleFirstViewDates) {
          this.modulesPatch = r.moduleFirstViewDates;
          r.moduleFirstViewDates.map((module: ModuleFirstViewDate) => {
            const menuNewTag = this.menusNewTag.find(
              menuNewTag =>
                menuNewTag.name.toUpperCase() ==
                module.moduleName.toUpperCase(),
            );
            if (menuNewTag) {
              menuNewTag.firstViewDate = module.firstViewDate;
            }
          });
        }
      });
  }

  private isCampaignOn(menuNewTag: MenuNewTag) {
    return (
      menuNewTag.campaignStartDate <= moment() &&
      menuNewTag.campaignEndDate > moment()
    );
  }

  private isFirstViewDateLessThan15Days(menuNewTag: MenuNewTag) {
    return (
      !menuNewTag.firstViewDate ||
      moment().diff(menuNewTag.firstViewDate, 'days') <
        daysSinceModuleFirstViewToShowNewTag
    );
  }

  activeMenu(title: SubMenuPermissions): boolean {
    return this.isActiveSubmenu.includes(title) && this.isSubmenuSelected;
  }

  enterMenu() {
    this.isSubmenuSelected = true;
  }

  selectedSubmenu(route: NavItem) {
    this.isSubmenuSelected = false;

    this.navItems.forEach(m => {
      m.active = false;
    });

    route.active = true;
  }
}
